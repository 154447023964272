import React from "react"
import cx from "classnames"
import * as classes from "./button.module.css"
import { getBuyerAppUrl, getHeroAppUrl } from "../../utils/config"

interface ButtonProps {
  isHero?: boolean
  heroApp?: boolean
  isTransparent?: boolean
  text: string
  className?: string
}

export const AppStoreButton: React.FunctionComponent<ButtonProps> = ({
  text,
  isHero = false,
  heroApp = false,
  isTransparent = false,
  className,
}) => (
  <a
    className={cx(
      classes.button,
      isHero && classes.heroButton,
      isTransparent && classes.transparentButton,
      className
    )}
    href={heroApp ? getHeroAppUrl() : getBuyerAppUrl()}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
)

export const Button: React.FunctionComponent<
  { isHero?: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ className, isHero, ...props }) => (
  <button
    className={cx(
      classes.button,
      !isHero && classes.formButton,
      isHero && classes.heroButton,
      className
    )}
    {...props}
  ></button>
)

export const LinkButton: React.FunctionComponent<
  { isHero?: boolean } & React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = ({ className, isHero, ...props }) => (
  <a
    className={cx(
      classes.button,
      !isHero && classes.formButton,
      isHero && classes.heroButton,
      className
    )}
    {...props}
  ></a>
)
