import React from "react"
import cx from "classnames"
import * as classes from "./containers.module.css"

export enum HeroBackground {
  Blue = "blue",
  Green = "green",
  Purple = "purple",
  DarkPurple = "dark-purple",
  Pink = "pink",
  None = "none",
}

export interface HeroContainerProps {
  autoSize?: boolean
  variant?: "light" | "dark" | "medium-dark" | "default"
  backgroundGradient?: HeroBackground
  className?: string
  containerClassName?: string
  lastContainer?: boolean
  noContainer?: boolean
}

interface HeroContentProps {
  className?: string
}

const HeroContainer: React.FunctionComponent<HeroContainerProps> = ({
  autoSize,
  variant = "default",
  backgroundGradient = "none",
  children,
  containerClassName,
  lastContainer,
  noContainer = false,
  className,
}) => {
  return (
    <div
      className={cx(
        classes.wrapper,
        variant === "light" && classes.variantLight,
        variant === "dark" && classes.variantDark,
        variant === "medium-dark" && classes.variantMediumDark,
        autoSize && classes.autoSize,
        lastContainer && classes.last,
        className
      )}
      style={
        backgroundGradient !== "none"
          ? {
              background: `var(--${backgroundGradient}-gradient)`,
            }
          : {}
      }
    >
      {noContainer ? (
        children
      ) : (
        <div className={cx("container", classes.content, containerClassName)}>
          {children}
        </div>
      )}
    </div>
  )
}

export default HeroContainer

export const HeroContent: React.FunctionComponent<HeroContentProps> = ({
  children,
  className,
}) => <div className={cx(classes.centeredContent, className)}>{children}</div>
